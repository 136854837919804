<template>
    <custom-table
        :title="$t('pages.ecommerce.order.shipping.title')"
        :subTitle="$t('pages.ecommerce.order.shipping.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        selectableRows
        :selectedRowEvents="selectedRowEvents"
        @action="handleClickAction"
        @changeTable="handleTableChange"
        filterComponentName="EcommerceOrderShippingFilter"
        @selectableRows="handleSelectedRow">
        <template v-slot:orderNo="{ row: record }">
            <router-link :to="'/ecommerce/order-management/order?id=' + record.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.id }}</span>
            </router-link>
        </template>
        <template v-slot:user="{ row: record }">
            <router-link :to="'/ecommerce/user?userID=' + record.user.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.user.full_name }}</span>
            </router-link>
            <br>
            <a :href="'mailto:' + record.user.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                {{ record.user.email }}
            </a>
        </template>
        <template v-slot:shipping="{ row: record }">
            {{ record.shipping_detail && record.shipping_detail.shipping_method ? record.shipping_detail.shipping_method.name : "-" }}
        </template>
        <template v-slot:shippingTrackingNumber="{ row: record }">
            {{ record.shipping_detail && record.shipping_detail.shipping_tracking_number ? record.shipping_detail.shipping_tracking_number : "-" }}
        </template>
        <template v-slot:shippingStatus="{ row: record }">
            <span v-if="record.shipping_detail" :class="shippingStatus[record.shipping_detail.shipping_status_id].badge" class="badge">{{ $t('pages.ecommerce.order.shipping.shippingStatus.' + shippingStatus[record.shipping_detail.shipping_status_id].code) }}</span>
            <span v-else>-</span>
        </template>
        <template v-slot:orderDate="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip v-if="record.shipping_detail && record.shipping_detail.shipping_status_id == 1" :content="$t('pages.ecommerce.order.shipping.selectedRowEvents.sendShipping')" popper-class="max-w-300px" placement="bottom">
                    <a v-on:click="sendShipping([record.id])" :data-kt-indicator="sendShippingLoading ? 'on' : null" :class="sendShippingLoading && 'pe-none'" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <span class="svg-icon svg-icon-3" v-if="!sendShippingLoading">
                            <inline-svg src="/media/icons/duotune/arrows/arr036.svg"/>
                        </span>
                        <span v-if="sendShippingLoading" class="indicator-progress">
                            <span class="spinner-border spinner-border-sm align-middle"></span>
                        </span>
                    </a>
                </el-tooltip>

                <el-tooltip v-if="record.shipping_detail && [3,4,5].includes(record.shipping_detail.shipping_status_id)" :content="$t('pages.ecommerce.order.shipping.selectedRowEvents.printShipping')" popper-class="max-w-300px" placement="bottom">
                    <a v-on:click="printShipping([record.id])" :data-kt-indicator="printShippingLoading ? 'on' : null" :class="printShippingLoading && 'pe-none'" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3" v-if="!printShippingLoading">
                            <inline-svg src="/media/icons/duotune/general/gen005.svg"/>
                        </span>
                        <span v-if="printShippingLoading" class="indicator-progress">
                            <span class="spinner-border spinner-border-sm align-middle"></span>
                        </span>
                    </a>
                </el-tooltip>

                <el-tooltip v-if="record.shipping_detail && [5, 6].includes(record.shipping_detail.shipping_status_id)" :content="$t('pages.ecommerce.order.shipping.detail.title')" placement="top">
                    <router-link :to="'/ecommerce/order-management/shipping/detail/' + record.id" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.order.shipping.cols.orderNo"),
                    key: "id",
                    scopedSlots: {customRender: "orderNo"}
                },
                {
                    name: this.$t("pages.ecommerce.order.shipping.cols.user"),
                    scopedSlots: {customRender: "user"}
                },
                {
                    name: this.$t("pages.ecommerce.order.shipping.cols.shipping"),
                    scopedSlots: {customRender: "shipping"}
                },
                {
                    name: this.$t("pages.ecommerce.order.shipping.cols.shippingTrackingNumber"),
                    scopedSlots: {customRender: "shippingTrackingNumber"}
                },
                {
                    name: this.$t("pages.ecommerce.order.shipping.cols.shippingStatus"),
                    scopedSlots: {customRender: "shippingStatus"}
                },
                {
                    name: this.$t("pages.ecommerce.order.shipping.cols.orderDate"),
                    scopedSlots: {customRender: "orderDate"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            shippingStatus: {
                1: {
                    code: 'pending',
                    badge: 'badge-primary'
                },
                2: {
                    code: 'manuelSend',
                    badge: 'badge-warning'
                },
                3: {
                    code: 'tobeProcessed',
                    badge: 'badge-light'
                },
                4: {
                    code: 'processed',
                    badge: 'badge-info'
                },
                5: {
                    code: 'shipped',
                    badge: 'badge-secondary'
                },
                6: {
                    code: 'delivered',
                    badge: 'badge-success'
                },
                7: {
                    code: 'error',
                    badge: 'badge-danger'
                }
            },
            selectedRowKeys: [],
            selectedRowEvents: [
                {
                    label: this.$t('pages.ecommerce.order.shipping.selectedRowEvents.sendShipping'),
                    validDataRule: this.validDataSelectedRows,
                    validDataRuleParams: ([1]),
                    event: this.sendShipping
                },
                {
                    label: this.$t('pages.ecommerce.order.shipping.selectedRowEvents.printShipping'),
                    validDataRule: this.validDataSelectedRows,
                    validDataRuleParams: ([3, 4, 5]),
                    event: this.printShipping
                }
            ],
            sendShippingLoading: false,
            printShippingLoading: false
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.order.shipping.table;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.orderShipping"), [this.$t("menu.ecommerceManagement"), this.$t("menu.orderManagement")]);
        this.$store.dispatch('ecommerce/order/shipping/get', {
            page: {
                pageSize: 30
            },
            filterData: Object.assign({
                sort: 'id:asc',
                shipping_statuses: [1, 3, 4, 5]
            }, this.$root.getFilterWithUrl({}))
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/order/shipping/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/order/shipping/get", {
                page: pagination,
                filterData: Object.assign({
                    shipping_statuses: [1, 3, 4, 5],
                    sort: 'id:asc'
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        validDataSelectedRows(shippingStatus = []) {
            let result = true;

            for (let i = 0; i < this.table.data.length; i++) {
                let record = this.table.data[i];

                if (this.selectedRowKeys.includes(record.id) && record.shipping_detail && !shippingStatus.includes(record.shipping_detail.shipping_status_id)) {
                    result = false;
                }
            }

            return result;
        },
        sendShipping(ids) {
            this.$confirm(this.$t('messages.sureAction'), this.$t('messages.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText: this.$t('common.cancel'),
                type: 'warning',
            }).then(() => {
                this.sendShippingLoading = true;

                this.axios.post(this.endpoints['ecommerce_order_shipping'], {
                    orders: ids
                }).then(response => {
                    let data = response.data.data;
                    let notify = {type: 'success', title: this.$t("messages.success"), message: this.$t("messages.actionOk"),};

                    if (data.items && data.items.failed && data.items.failed.length > 0) {
                        let errors = data.items.failed.map((error) => {
                            return this.sprintf('%d - %s', [error.id, this.$t('pages.ecommerce.order.shipping.responseErrors.' + error.error, error.error)])
                        })

                        notify = {type: 'error', title: this.$t("messages.error"), message: errors.join('</br>')};
                    }

                    this.$notify({type: notify.type, title: notify.title, message: notify.message});
                    this.refreshTable();
                }).catch(error => {
                    this.onResponseFailure(error.response.data);
                }).finally(() => {
                    this.sendShippingLoading = false;
                });
            });
        },
        printShipping(ids) {
            this.trustedWindowOpen(this.sprintf('/ecommerce/order-management/shipping/preview/%s', [ids.join(',')]), '_blank')
        }
    }
}
</script>

<style>
</style>